import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import the useParams hook
import axios from "axios";
import img from "../Assets/banner.png";
import img1 from "../Assets/profile.png";
import img2 from '../Assets/img3.png'
import collegefee from "../Assets/collegefee.png";
import hostelfee from "../Assets/hostelfee.png";
import messfee from '../Assets/messfee.png';
import total from '../Assets/total.png';
import overall from '../Assets/overall.png';
import classes from "./Billing.module.css";
import msalInstance from "../msalConfig";



function StudentProfile() {
  const { studentid } = useParams(); // Use useParams to get the route parameter
  const [studentData, setStudentData] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [hostelFee, setHostelFee] = useState(null);
  const [messFee, setMessFee] = useState(null);
  const[college, setCollegeFee] = useState(null);
  const [collectedFee, setCollectedFee] = useState(null);
  const [fee, setFee] = useState(null);
  const [pendingFeee, setPendingFeee] = useState(null);
  const [visaFee, setVisaFee] = useState(null);
  const [registrationFee, setRegistrationFee] = useState(null);
  const [fees, setFees] = useState([
    {
        
        year: Number,
        fee_to_be_collected: Number,
        mess_fee: Number,
        visa_fee: Number,
        hostel_fee: Number,
        visa_extension_fee:Number,
       
        

    },
]);
  const [yeartotal, setYeartotal] = useState(null);
  
  
  const [formData, setFormData] = useState({
    studentid : "",
    firstname : "",
    lastname : "",
    email : "",
    passportnumber : "",
    phone : "",
    address : "",
    city : "",
    state : "",
    country : "",
    zipcode : "",
    dateofbirth : "",
    gender : "",
    nationality : "",
    fathername : "",
    localphone : "",
    fatheroccupation : "",
    year:"",
  
    
    
  });
  const handleFeeChange = (event) => {
    setFormData(event.target.value);
  };

  const handleFeesChange = (index, field, value) => {
    const updatedFees = fees.map((fee, i) => 
        i === index ? { ...fee, [field]: parseInt(value) } : fee
    );
    setFees(updatedFees);
};


console.log("fees", fees)
  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        // Fetch the token
        const token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"]
        });
  
        // Make the API request with the token
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/yearlyfee/${studentid}`,
          {
            headers: {
              Authorization: `Bearer ${token.idToken}`,
            }
          }
        );
       // Update state with the fetched data
        setCollectedFee(response.data.fee_collected);
        setFee(response.data.fee_to_be_collected);
        setHostelFee(response.data.hostel_fee);
        setMessFee(response.data.mess_fee);
        setVisaFee(response.data.visa_fee);
        setRegistrationFee(response.data.visa_extension_fee);
        setCollegeFee(response.data.fee_to_be_collected);
        setYeartotal(response.data.total_fee);
        

        console.log("hostel",response.data.hostel_fee);

  
        console.log(response.data);
        console.log(response.data.fee_to_be_collected);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };
  
    fetchStudentData();
  }, [studentid]);  // Dependency array with searchid
  


  const handleUpdateeer = async () => {
    try {
      // Assuming you want to update the first fee object in the array
      const feeToUpdate = fees[0];
  
      // Dynamically build the payload with only the updated fields
      const payload = {};
      const originalFee = {
        fee_to_be_collected:  Number,
            mess_fee: Number,
            visa_fee: Number,
            hostel_fee: Number,
            visa_extension_fee:Number
      };
  
      for (const key in feeToUpdate) {
        if (feeToUpdate[key] !== originalFee[key]) {
          payload[key] = feeToUpdate[key];
        }
      }
  
      // Ensure the payload is not empty before making the request
      if (Object.keys(payload).length === 0) {
        alert("No changes detected to update.");
        return;
      }
  
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/update/${studentid}/${feeToUpdate.year}`,
        payload
      );
  
      console.log("Response:", response.data);
      console.log("studentid:", studentid);
      console.log("Year:", feeToUpdate.year);
      console.log("Payload:", payload);
  
      alert("Fee updated successfully!");
    } catch (error) {
      console.error("Error updating fees:", error);
      alert("Failed to update fees. Please try again.");
      
      
      
    }
  };
  
  

useEffect(() => {
  // Function to fetch data
  const fetchData = async () => {
    try {
      const accounts = msalInstance.getAllAccounts();
          if (accounts.length === 0) {
              throw new Error("No accounts found");
          }
          msalInstance.setActiveAccount(accounts[0]);
      const token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"]
    });
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/collectedfee/calculatePendingFee?student_id=${studentid}`, {
        headers: {
          authorization: `Bearer ${token.idToken}`
        }
      });
      setPendingFeee(response.data.pending);  
      console.log("PendingFee:", response.data.pending);
      setCollectedFee(response.data.total_collected); 
    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  };

  
  fetchData();
    
}, [studentid]);

useEffect(() => {
  if (pendingFeee !== null) {
    console.log("PendingFee:", pendingFeee);  
  }
}, [pendingFeee]); 


   



  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                throw new Error("No accounts found");
            }
            msalInstance.setActiveAccount(accounts[0]);
        var token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"],
        });

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${studentid}`,
          {
            headers: {
              Authorization: `Bearer ${token.idToken}`,
            },
          }
        );
        setStudentData(response.data);
        console.log(response.data); // Assuming API response is an object with student data
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };

    fetchStudentData();
  }, [studentid]);

  // Fixed College Fee
  const collegeFee = 2600;

  // Extracting the college fee collected if available
  const totalPaidFee = studentData?.billingfor?.[0]?.collegefee_collected || "0";
  const totalPaidHostelFee = studentData?.billingfor?.[0]?.hostel_fee_collected || "0";
  const totalPaidMessFee = studentData?.billingfor?.[0]?.mess_fee_collected || "0";
  const totalPaidOverall = studentData?.billingfor?.[0]?.overall_pending || "0";
  const totalfee = studentData?.totalfee || "0";
  const pandingFee = totalfee - totalPaidFee;
  console.log("totalPaidFee", pandingFee);

  // Calculating the pending fee
  const pendingFee = totalfee - collegeFee;

  // Dividing the remaining fee by 2 and adding it to hostel and mess fee
  const distributedFee = pendingFee / 2;
  const updatedHostelFee = parseInt(totalPaidHostelFee) + distributedFee;
  const updatedMessFee = parseInt(totalPaidMessFee) + distributedFee;
  const handleupdate = async () => {

    try {
      const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                throw new Error("No accounts found");
            }
            msalInstance.setActiveAccount(accounts[0]);
      var token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"],
      });
      const response = axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${studentid}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token.idToken}`,
          }
        }
      );
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  }


  return (
    <div
      className="container-fluid min-vh-100 d-flex justify-content-center"
      style={{ backgroundColor: "#1E1E2E" }}

    >

      <div className="row col-12">
        <nav
          style={{
            "--bs-breadcrumb-divider": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E")`,
            fontSize: "1.5rem",
            fontWeight: 500,
            backgroundColor: "#1E1E2E",
          }}
          aria-label="breadcrumb"
          className="p-3 text-white"
        >
          <ol className="breadcrumb m-2">
            <li className="breadcrumb-item text-white">Student Dashboard</li>
            <li className="breadcrumb-item active" aria-current="page"></li>
          </ol>
        </nav>
        <div className="container row mx-auto mb-5 justify-content-center">
          <div className="col-lg-9">
            <div
              className="container rounded"
              style={{ backgroundColor: "#2D2F3A" }}
            >
              <div className="col">
                <img src={img} style={{ width: "100%" }} />
                <img
                  src={img1}
                  className="d-flex justify-content-start"
                  style={{
                    marginTop: "-60px",
                    marginLeft: "40px",
                  }}
                />
                <div className="row m-4" style={{ color: "#ffffff" }}>
                  <div className="col text-start">

                    <p>
                      {studentData.firstname} {studentData.lastName}
                    </p>
                    <p className="text-start" style={{ color: "#EEEEEE" }}>
                      {studentData. email}
                    </p>
                  </div>
                  <div className="col text-end">
                    <p>
                      <button type="button" className="btn btn-secondary">
                        {studentData.studentid}
                      </button>
                    </p>
                  </div>
                </div>

                <div className="row m-4">
                  <div className="col">
                    <label
                      htmlFor="floatingFirstName"
                      className="form-label"
                      style={{ color: "#EEEEEE" }}
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingFirstName"
                      value={studentData.firstname}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                      }}
                      onChange={(e) =>
                        setStudentData({ ...studentData, firstName: e.target.value })
                    }
                    disabled={!isEditable}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="floatingLastName"
                      className="form-label"
                      style={{ color: "#EEEEEE" }}
                    >
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingLastName"
                      value={studentData.lastname}
                      onChange={(e) =>
                        setStudentData({ ...studentData, firstName: e.target.value })
                    }
                    disabled={!isEditable}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                      }}
                    />
                  </div>
                </div>

                <div className="row m-4">
               
                  <div className="col">
                    <label
                      htmlFor="floatingPassport"
                      className="form-label"
                      style={{ color: "#EEEEEE" }}
                    >
                      Passport Number
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingPassport"
                      value={studentData.passportnumber}
                      onChange={(e) =>
                        setStudentData({ ...studentData, firstName: e.target.value })
                    }
                    disabled={!isEditable}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                      }}
                    />
                  </div>
                </div>

                <div className="row m-4">
                  <div className="col">
                    <label
                      htmlFor="floatingNationality"
                      className="form-label"
                      style={{ color: "#EEEEEE" }}
                    >
                      Nationality
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingNationality"
                      value={studentData.nationality}
                      onChange={(e) =>
                        setStudentData({ ...studentData, firstName: e.target.value })
                    }
                    disabled={!isEditable}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                      }}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="floatingNationality"
                      className="form-label"
                      style={{ color: "#EEEEEE" }}
                    >
                      Country
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingNationality"
                      value={studentData.country}
                      onChange={(e) =>
                        setStudentData({ ...studentData, firstName: e.target.value })
                      }
                    disabled={!isEditable}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                      }}
                    />
                  </div>
                </div>
                <div className="row m-4">
                  <div className="col-md-6">
                    <label
                      htmlFor="floatingAddress"
                      className="form-label"
                      style={{ color: "#EEEEEE",  width: "30px" }}
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingAddress"
                      value={studentData.address}
                      onChange={(e) =>
                        setStudentData({ ...studentData, firstName: e.target.value })
                    }
                    disabled={!isEditable}
                    style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                        
                      }}
                    />
                  </div>
                </div>
                <div className="row m-4">
                  <div className="col">
                    <label
                      htmlFor="floatingYear"
                      className="form-label"
                      style={{ color: "#EEEEEE" }}
                    >
                      Year
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingYear"
                      value={studentData.year}
                      onChange={(e) =>
                        setStudentData({ ...studentData, firstName: e.target.value })
                    }
                    disabled={!isEditable}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                      }}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="floatingAddress"
                      className="form-label"
                      style={{ color: "#EEEEEE" }}
                    >
                      Date of birth
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingAddress"
                      placeholder="Address"
                      value={studentData.dateofbirth || "na"}
                      // readOnly
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                      }}
                    />
                  </div>

                </div>
                <div className="row m-4">
                  <div className="col">
                    <label
                      htmlFor="floatingPhoneLocal"
                      className="form-label"
                      style={{ color: "#EEEEEE" }}
                    >
                      Phone (Local)
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingPhoneLocal"
                      value={studentData.localphone}
                      onChange={(e) =>
                        setStudentData({ ...studentData, firstName: e.target.value })
                    }
                    disabled={!isEditable}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                      }}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="floatingPhoneParent"
                      className="form-label"
                      style={{ color: "#EEEEEE" }}
                    >
                      Phone (Parent)
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingPhoneParent"
                      value={studentData.phone}
                      onChange={(e) =>
                        setStudentData({ ...studentData, firstName: e.target.value })
                    }
                    disabled={!isEditable}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                      }}
                    />
                  </div>
                </div>
                <div className="row m-4">
                  <div className="col">
                    <label
                      htmlFor="floatingFatherName"
                      className="form-label"
                      style={{ color: "#EEEEEE" }}
                    >
                      Father Name
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingFatherName"
                      value={studentData.fathername}
                      onChange={(e) =>
                        setStudentData({ ...studentData, firstName: e.target.value })
                    }
                    disabled={!isEditable}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                      }}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="floatingOccupation"
                      className="form-label"
                      style={{ color: "#EEEEEE" }}
                    >
                      Father Occupation
                    </label>
                    <input
                      type="text"
                      className="form-control mb-3"
                      id="floatingOccupation"
                      value={studentData. fatheroccupation}
                      onChange={(e) =>
                        setStudentData({ ...studentData, firstName: e.target.value })
                    }
                    disabled={!isEditable}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#EEEEEE",
                      }}
                    />
                  </div>
                 
                </div>
                <div>
                            <div style={{ padding: '20px', margin: 'auto' }}>
                             <h6 style={{ color: "#EEEEEE" }}>FeeUpdation Management</h6>
                            <table style={{ width: '100%', borderCollapse: 'collapse', border: "none", width: "100%" }}>
                         <thead style={{ backgroundColor: '#3A3F51' }}>
                        <tr>

                        <th style={{ padding: '8px', color: "#A0A0A0",fontWeight: 500,}}>Year</th>
                        <th style={{ padding: '8px', color: "#A0A0A0",fontWeight: 500,}}>College Collected</th>
                        <th style={{padding: '8px', color: "#A0A0A0",fontWeight: 500, }}>Mess Fee</th>
                        <th style={{ padding: '8px', color: "#A0A0A0",fontWeight: 500,}}>Visa Fee</th>
                        <th style={{padding: '8px', color: "#A0A0A0",fontWeight: 500, }}>Hostel Fee</th>
                        <th style={{ padding: '8px', color: "#A0A0A0",fontWeight: 500,}}>Registration Fee</th>

                        
                        </tr>
                       </thead>
                       <tbody>
                       {fees.map((fee, index) => (
                        <tr key={index} style={{border: "1px solid #3E3E3E" }}>
                           
                            <td style={{ color:"#EEEEEE", padding: '8px'  }}>
                                <input
                                    type="number"
                                    value={fee.year}
                                    onChange={(e) => handleFeesChange(index, 'year', e.target.value)}
                                    style={{ width: '100%', padding: '5px' }}
                                />
                            </td>
                            <td style={{ color:"#EEEEEE", padding: '8px'  }}>
                                <input
                                    type="number"
                                    value={fee.fee_to_be_collected}
                                    onChange={(e) => handleFeesChange(index, 'fee_to_be_collected', e.target.value)}
                                    style={{ width: '100%', padding: '5px' }}
                                />
                            </td>
                            <td style={{color:"#EEEEEE", padding: '8px' }}>
                                <input
                                    type="number"
                                    value={fee.mess_fee}
                                    onChange={(e) => handleFeesChange(index, 'mess_fee', e.target.value)}
                                    style={{ width: '100%', padding: '5px' }}
                                />
                            </td>
                            <td style={{color:"#EEEEEE", padding: '8px'  }}>
                                <input
                                    type="number"
                                    value={fee.visa_fee}
                                    onChange={(e) => handleFeesChange(index, 'visa_fee', e.target.value)}
                                    style={{ width: '100%', padding: '5px' }}
                                />
                                
                            </td>
                            <td style={{ color:"#EEEEEE", padding: '8px'  }}>
                                <input
                                    type="number"
                                    value={fee.hostel_fee}
                                    onChange={(e) => handleFeesChange(index, 'hostel_fee', e.target.value)}
                                    style={{ width: '100%', padding: '5px' }}
                                />
                                   
                            </td>
                            <td style={{ color:"#EEEEEE", padding: '8px' }}>
                                <input
                                    type="number"
                                    value={fee. visa_extension_fee}
                                    onChange={(e) => handleFeesChange(index, ' visa_extension_fee', e.target.value)}
                                    style={{ width: '100%', padding: '5px' }}
                                />
                                  
                            </td>
                            
                            
                        </tr>
                    ))}
                </tbody>
            </table>
    
        </div>
                            </div>
                {/* Other fields here... */}
                <div
                  className="d-flex justify-content-end gap-3"
                  style={{ padding: "20px 30px" }}
                >

                  <button
                      type="button"
                      className="btn"
                      style={{
                      backgroundColor: "#4182F9",
                      color: "#FFFFFF",
                      width: "100px",
                      }}
                      onClick={handleUpdateeer}
                      >
                       UpdateFee
                      </button>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 mt-4">
            <div className="row gap-3 d-flex flex-wrap justify-content-center">
              <div
                className="col-lg-3 col-md-6 col-sm-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                style={{
                  width: "360px",
                  height: "100px",
                  backgroundColor: "rgba(45, 47, 58, 1)",
                }}
              >
                <div>
                <span className={classes.cardstitles}>
                    College Fee Collected
                  </span>
                  <br />
                  <span className={classes.cardsnumber}>{college}</span>
                  {/* <button onClick={handleupdate}>Update Fee</button> */}
                </div>
                <img
                  src={collegefee}
                  width={40}
                  height={40}
                  alt="College Fee"
                />
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                style={{
                  width: "360px",
                  height: "100px",
                  backgroundColor: "rgba(45, 47, 58, 1)",
                }}
              >
                <div>
                  <span className={classes.cardstitles}>
                    Hostel Fee Collected
                  </span>
                  <br />
                  <span className={classes.cardsnumber}>{hostelFee}</span>
                </div>
                <img
                  src={img2}
                  width={40}
                  height={40}
                  alt="Hostel Fee"
                />
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                style={{ width: '360px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
              >
                <div>
                  <span className={classes.cardstitles}>Mess Fee Collected</span><br />
                  <span className={classes.cardsnumber}>{messFee}</span>
                </div>
                <img src={messfee} width={40} height={40} alt="Total Tickets Sold" />
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                style={{ width: '360px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
              >
                <div>
                  <span className={classes.cardstitles}>Visa Extension</span><br />
                  <span className={classes.cardsnumber}>{visaFee}</span>
                </div>
                <img src={img2} width={40} height={40} alt="Total Tickets Sold" />
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                style={{ width: '360px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
              >
                <div>
                  <span className={classes.cardstitles}>Visa Registration </span><br />
                  <span className={classes.cardsnumber}>{registrationFee}</span>
                </div>
                <img src={messfee} width={40} height={40} alt="Total Tickets Sold" />
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                style={{ width: '360px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
              >
                <div>
                  <span className={classes.cardstitles}>Total Fee</span><br />
                  <span className={classes.cardsnumber}>{yeartotal}</span>
                </div>
                <img src={img2} width={40} height={40} alt="Total Tickets Sold" />
              </div>

              <div
                className="col-lg-3 col-md-6 col-sm-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                style={{ width: '360px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
              >
                <div>
                  <span className={classes.cardstitles}>Total paid Fee</span><br />
                  <span className={classes.cardsnumber}>{collectedFee}</span>
                </div>
                <img src={total} width={40} height={40} alt="Total Revenue" />
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                style={{ width: '360px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
              >
                <div>
                  <span className={classes.cardstitles}>Pending Fee</span><br />
                  <span className={classes.cardsnumber}>{pendingFeee}</span>
                </div>
                <img src={overall} width={40} height={40} alt="Total Revenue" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentProfile;