import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import Login from "../Assets/JALAL-ABAD_STATE_UNIVERSITY__4_-removebg-preview.png";
import "font-awesome/css/font-awesome.min.css";
import axios from 'axios';
import styles from './Loginpage.module.css';
import { useMsal } from "@azure/msal-react";
import msalInstance from "../msalConfig";



const LoginPage = () => {
  const [isAdminMode, setIsAdminMode] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleToggle = () => {
    setIsAdminMode(!isAdminMode);
  };

  console.log(process.env.REACT_APP_BASE_URL);
  console.log(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent`);


  const { instance } = useMsal();

  const handleLogin = async () => {
      try {
          await instance.loginRedirect({
              scopes: ["User.Read"],
          });
      } catch (error) {
          console.error("Login failed:", error);
      }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

   

    handleLogin();
   
  };

  return (
    <div className={`container-fluid vh-100 d-flex ${styles.container} fadeInAnimation`}>
      <div className={`${styles.row} ${isAdminMode ? "" : styles.reverse}`}>
        {/* Image Section */}
        <div className={`col-md-6 p-0 ${styles.imageSection} fadeInAnimation`} />

        {/* Login Form Section */}
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-center slideInFromRight">
          <h1 className="text-center text-light">Login</h1>
          <form
            onSubmit={handleSubmit}
            className={`w-75 mt-4 p-5 ${styles.form}`}
          >
           

            <button
              type="submit"
              className={`btn btn-primary w-100 mt-3 rounded-pill ${styles.loginButton} zoomInAnimation`}
            >
              Login with Microsoft 365
            </button>

            

            {/* Forgot Password and Sign Up Links */}
            
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;