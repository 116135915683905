import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import axios from "axios";
import "./Signup.css"; // Import your custom CSS file for animations
import msalInstance from "../msalConfig";

const Signup = () => {
  const [role, setRole] = useState("User");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [primaryKey, setPrimaryKey] = useState("");
  const [signupPending, setSignupPending] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [user, setUser] = useState('User');

  useEffect(() => {
    if (role === "Admin") {
      setEmail("admin@example.com");
      setPassword("admin123");
      setPrimaryKey("12345");
    } else {
      setEmail("");
      setPassword("");
      setPrimaryKey("");
    }
  }, [role]);

 
  

  const handleSignup = async (e) => {
    if (role=="Admin") {
    
      localStorage.setItem('userRole',JSON.stringify( role)); 
      
    }
    e.preventDefault();
    try {
      if (role === "Admin") {
        if (email === "admin@example.com" && password === "admin123" && primaryKey === "12345") {
          alert("Admin login successful!");
          navigate("/app/dash");
        } else {
          setError("Incorrect admin credentials.");
        }
      } else {
        setSignupPending(true);
        const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                throw new Error("No accounts found");
            }
            msalInstance.setActiveAccount(accounts[0]);
        const token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"]
      });
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/approvel/userr`, { email, password }, {
          headers: {
              Authorization: `Bearer ${token.idToken}`
          }
        });
        if (response.status === 201) {
          alert("User signup successful! Awaiting approval.");
        } else {
          setError("Failed to submit signup request.");
        }
      }
    } catch {
      setError("An error occurred. Please try again.");
    } finally {
      setSignupPending(false);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100" style={{ background: "#1E1E2E" }}>
      <div className="p-5 rounded shadow signup-form" style={{ background: "#2D2F3A", maxWidth: "400px", width: "100%" }}>
        <h2 className="text-center text-light heading">{role === "Admin" ? "Admin Login" : "User Signup"}</h2>
        {error && <div className="alert alert-danger error">{error}</div>}
        <form onSubmit={handleSignup}>
          <div className="form-group mb-3">
            <label className="text-light">Email</label>
            <input
              type="email"
              className="form-control input-field"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={role === "Admin"}
            />
          </div>
          <div className="form-group mb-3">
            <label className="text-light">Password</label>
            <input
              type="password"
              className="form-control input-field"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={role === "Admin"}
            />
          </div>
          {role === "Admin" && (
            <div className="form-group mb-3">
              <label className="text-light">Primary Key</label>
              <input
                type="text"
                className="form-control input-field"
                placeholder="Enter primary key"
                value={primaryKey}
                onChange={(e) => setPrimaryKey(e.target.value)}
              />
            </div>
          )}
          <div className="form-group mb-3">
            <label className="text-light">Role</label>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="form-select input-field"
              style={{ background: "#333", color: "#fff" }}
            >
              <option value="User">User</option>
              <option value="Admin">Admin</option>
            </select>
          </div>
          <button
            type="submit"
            className="btn btn-primary w-100 submit-button"
            disabled={signupPending}
            style={{ background: "#5865F2", borderColor: "#5865F2" }}
          >
            {role === "Admin" ? "Login" : "Signup"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
