import { useState, useEffect } from "react";
import { Layout, Menu, Button } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { createBrowserRouter } from "react-router-dom";
import dash from '../Assets/message.png'
import Bell from '../Assets/Bell.png'
import Classes from "./Navbar.module.css";
import profile from '../Assets/profile.png'
import Mail from '../Assets/mail.png'
import Message from '../Assets/message.png'
import 'bootstrap-icons/font/bootstrap-icons.css';
import Dashboard from '../Assets/home.png'
import Student from '../Assets/Student.png'
import Account from '../Assets/Account.png'
import Settings from '../Assets/Settings.png'
import Logout from '../Assets/logout.png'
import Menubar from '../Assets/menu.png'
import { Drawer } from 'antd'
import SettingsPanel from '../Components/SettingsPanel'
import Hive from '../Components/HiveReceipt'
import { FaAngleRight } from "react-icons/fa";
import login from "../Assets/logoes.png";
import EsaraPayment from './EsaraPayment'

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const Navbar = () => {
  const navigate = useNavigate();
  const [clickedButton, setClickedButton] = useState(null);

  const [storedData, setStoredData] = useState(null);

  useEffect(() => {

    const data = localStorage.getItem("userRole");


    if (data) {
      try {
        setStoredData(JSON.parse(data));
      } catch (e) {
        setStoredData(data);
      }
    }
  }, []);

  console.log("storedData", storedData);

  const handlelogout = (path) => {
    navigate(path);
    localStorage.removeItem("userRole");
  }

  const handleMenuClick = (path) => {
    if (path === "/app/settings") {
      toggleSettingsSidebar();
    } else {
      setClickedButton(path);
      navigate(path);
    }
  };

  const [isDarkMode, setIsDarkMode] = useState(false);


  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    // document.body.classList.toggle("dark-theme", !isDarkMode);
  };

  const ProfileImage = ({ profile }) => {

    const navigate = useNavigate();
  }

  const [muted, setMuted] = useState(false);

  const toggleMute = () => {
    setMuted(!muted);
  }

  const [isSettingsSidebarVisible, setIsSettingsSidebarVisible] = useState(false);

  const toggleSettingsSidebar = () => {
    setIsSettingsSidebarVisible(!isSettingsSidebarVisible);
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(true); // State to control sidebar visibility
  const [clickedButton1, setClickedButton1] = useState("1"); // Track selected menu item

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible); // Toggle sidebar visibility
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth < 768;
      setIsSmallScreen(smallScreen);
      setIsSidebarVisible(!smallScreen); // Hide sidebar on small screens
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [openKeys, setOpenKeys] = useState([]);

  const handleOpenChange = (keys) => {
    // Only keep top-level menus (students or account) open at the top level
    const rootSubmenus = ["students", "account"];
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));

    if (rootSubmenus.includes(latestOpenKey)) {
      // If opening a top-level menu, close others
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      // If opening a nested submenu, keep its parent open
      setOpenKeys(keys);
    }
  };



  return (
    <Layout
      style={{ Height: "100vh", width: "100%", overflow: "hidden" }}
      className="d-flex flex-column"
    >
      <Header
        className={Classes.navbar}
        style={{
          backgroundColor:"#2D2F3A",
          // color: isDarkMode ? "#FFFFFF" : "#000000",
          width: "104vw", // Full viewport height
          // position: "fixed", // Fixes the sidebar position on the page
          top: 0,
          left: 0,
          // zIndex: 5,
        }}
      >
        <nav className="navbar navbar-expand-lg">
          <div
            className="container-fluid"
            style={{
              // backgroundColor: isDarkMode ? "#2D2F3A" : "#EAEAEA",
              color: isDarkMode ? "#FFFFFF" : "#000000",
              width: "100%",
              display: "flex",
              // justifyContent: "space-between",
              flexWrap: "nowrap",
              marginLeft: "150px",
              marginTop: '-8px',
              marginLeft: isSidebarVisible ? "150px" : "0"
            }}
          >
            <div
              className="d-flex justify-content-start"
              style={{ flexWrap: "nowrap", width:'100%' }}
            >
              <form className="d-flex" role="search" >
                <div className="input-group" style={{ maxWidth: '700px' }}>
                  <span
                    className="input-group-text d-none d-sm-block"
                    style={{ backgroundColor: "#FFFFFF" }}
                  >
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    style={{
                      backgroundColor: "#FFFFFF",
                    }}
                  />
                </div>
              </form>
            </div>
            {/* Icons on the right */}
            <div className="d-flex align-items-center">
             
                
            
              <div
                className="mx-3 d-none d-md-block "
              >
                <img
                  src={Mail}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalToggle1"
                  alt="Messages"
                  style={{ cursor: "pointer", color:"white" }}
                />
                <div
                  className="modal fade"
                  id="exampleModalToggle1"
                  aria-hidden="true"
                  aria-labelledby="exampleModalToggleLabel1"
                  tabIndex="-1"
                >
                  <div
                    className="modal-dialog modal-md"
                    style={{
                      position: "absolute",
                      top: "80px",
                      right: "70px",
                      margin: "0",
                    }}
                  >
                   <div
  className="modal-content "
  style={{ backgroundColor: "#2D2F3A", width: "400px", textAlign: "center" }}
>
  <div className="p-5 d-flex flex-column align-items-center justify-content-center">
    <h3
      className="modal-title fs-4 mb-3 text-white"
      id="exampleModalToggleLabel1"
    >
      Coming Soon!
    </h3>
    <p className="text-secondary small mb-4">
      We're working on something amazing. Stay tuned!
    </p>
    <div
      className="spinner-border text-light"
      role="status"
      style={{ width: "3rem", height: "3rem" }}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
</div>




                  </div>









                </div>
              </div>








              <div
                className="mx-3 d-none d-md-block"
                onClick={toggleMute}
                style={{ cursor: "pointer", position: "relative" }}
              >
                <img
                  src={Bell}
                  className={`bi ${muted ? "bi-bell-slash" : "bi-bell"}`}
                  style={{
                    position: "relative",
                    color: muted ? "gray" : "inherit",
                  }}
                ></img>{" "}
                {muted && (
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "0",
                      right: "0",
                      height: "2px",
                      backgroundColor: "red",
                      transform: "rotate(-45deg)",
                      transformOrigin: "center",
                    }}
                  ></span>
                )}
              </div>
              <div
                className="mx-3"
                onClick={() => navigate("/app/admin")}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={profile}
                  alt="Profile"
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                />{" "}
              </div>
            </div>
          </div>
        </nav>
      </Header>

      <div className="d-flex h-100">
        {/* Button to toggle Sidebar visibility */}

        {isSmallScreen && (
          <Button
            className="navbar-toggler-icon"
            style={{
              position: "absolute",
              top: "20px", // Adjust this if you want to change the vertical position
              left: "25px", // Adjust this to the horizontal position you prefer
              zIndex: 10,
              backgroundColor: 'transparent',
              border: 'none',
              display: 'flex', // Flexbox for centering the content
              justifyContent: 'center', // Aligns the content horizontally
              alignItems: 'center', // Aligns the content vertically
              padding: '0', // Remove any default padding
            }}
            onClick={toggleSidebar}
          >
            <img
              src={Menubar}
              alt={isSidebarVisible ? "Close Sidebar" : "Open Sidebar"}
              style={{ width: '25px', height: '25px' }}
            />
          </Button>
        )}
        {/* Sidebar */}
        {isSidebarVisible && (
          <Sider
            className="side "
            style={{
              backgroundColor: isDarkMode ? "#2D2F3A" : "#2D2F3A",
              color: isDarkMode ? "#FFFFFF" : "#FFFFFF",
              height: "100vh", // Full viewport height
              position: "fixed", // Fixes the sidebar position on the page
              width: '600px',
              top: 0,
              left: 0,
              zIndex: 5,
            }}
          >
            {/* Logo Section */}
           <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px 0",
    color: '#FFF'
  }}
>
  <img
   src={login}
    alt="Logo"
    style={{
      maxWidth: "100%",
      height: "130px",
      bottom:"40px",
      position:"relative"
    
    }}
  />
</div>


            {/* Sidebar Menu */}
            <Menu
  theme={isDarkMode ? "dark" : "dark"} // Retain dark mode
  mode="inline"
  className="customMenu mt-0"
  style={{
    backgroundColor: isDarkMode ? "#2D2F3A" : "#2D2F3A", // Dark theme background
    color: isDarkMode ? "#FFFFFF" : "#FFFFFF", // Text color for dark theme
    bottom:"55px",
    position:"relative"
  }}
  openKeys={openKeys}
  onOpenChange={handleOpenChange}
  selectedKeys={[clickedButton]}
>
              {/* Dashboard menu item */}
              <Menu.Item key="1" onClick={() => handleMenuClick("/app/dash")} style={{ backgroundColor: 'transparent', color: '#fff' }}>
                <img src={Dashboard} alt="Dashboard" style={{ marginRight: '8px' }} />
                Dashboard
              </Menu.Item>

              {/* COMMUNITY label */}
              <Menu.Item key="community" disabled>
                COMMUNITY
              </Menu.Item>

              {/* Students submenu */}
              <SubMenu
                key="students"
                title={<span><img src={Student} alt="Students Icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} /> Students</span>}
              >
                <Menu.Item key="2" onClick={() => handleMenuClick("/app/studentdata")}>
                  <FaAngleRight /> Student Data
                </Menu.Item>
                <Menu.Item key="3" onClick={() => handleMenuClick("/app/addstudent")}>
                  <FaAngleRight />  Add Student
                </Menu.Item>
                <Menu.Item key="4" onClick={() => handleMenuClick("/app/studentprofile")}>
                  <FaAngleRight />  Student Profile
                </Menu.Item>
                {/* <Menu.Item key="5" onClick={() => handleMenuClick("/app/approvel")}>
                  <FaAngleRight />  Approvel
                </Menu.Item> */}
              </SubMenu>

              {/* Account submenu */}
              <SubMenu key="account" title={<span><img src={Account} alt="Students Icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} /> Account</span>}>
              
                <SubMenu key="feePayment" title="Fee Payment">
                  <Menu.Item key="collegePayment" onClick={() => handleMenuClick("/app/feepayment")}> <FaAngleRight /> College Payment</Menu.Item>
                  <Menu.Item key="esaraPayment" onClick={() => handleMenuClick("/app/esarapayment")}> <FaAngleRight /> Esara Payment</Menu.Item>
                  
                </SubMenu>
                <Menu.Item key="6" onClick={() => handleMenuClick("/app/receipt")}>
                  <FaAngleRight /> Receipt
                </Menu.Item>

              </SubMenu>

              {/* SUBJECT label */}
              <Menu.Item key="subject" disabled>
                SUBJECT
              </Menu.Item>
            </Menu>

            {/* Settings and Logout menu items */}
            <div style={{ position: "absolute", bottom: 0, width: "100%" }}>
              <Menu
                theme="dark"
                mode="inline"
                style={{ backgroundColor: isDarkMode ? "#2D2F3A" : "#2D2F3A", color: isDarkMode ? "#FFFFFF" : "#FFFFFF", }}

                items={[
                  {
                    key: '7',
                    label: (
                      <span>
                        <img src={Settings} alt="Settings Icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                        Settings
                      </span>
                    ),
                    onClick: () => handleMenuClick('/app/settings'),
                    style: { backgroundColor: 'transparent' }
                  },
                  {
                    key: '8',
                    label: (
                      <span>
                        <img src={Logout} alt="Settings Icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                        Logout
                      </span>
                    ),
                    onClick: () => handleMenuClick('/'),
                    style: { backgroundColor: 'transparent' }
                  }
                ]}
              />
            </div>

            <Drawer
              placement="right"
              closable={false}
              onClose={toggleSettingsSidebar}
              visible={isSettingsSidebarVisible}
              width={400}
              style={{ backgroundColor: isDarkMode ? "#2D2F3A" : "#EAEAEA", color: isDarkMode ? "#FFFFFF" : "#000000", }}
            >
              {/* Add your settings content here */}
              <SettingsPanel />
            </Drawer>
          </Sider>
        )}
        <Layout style={{ marginLeft: isSidebarVisible && !isSmallScreen ? "200px" : "0", backgroundColor: isDarkMode ? "#1E1E2E" : "#FFFFFF" }} >
          <Content  >
            <Outlet />
          </Content>
        </Layout>
      </div>
    </Layout>
  );
};

export default Navbar;