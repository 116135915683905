import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { message } from "antd";
import msalInstance from "../msalConfig";


const PaymentReceipt = () => {
  const [rows, setRows] = useState([["", "", "", "", "", ""]]);
  const [studentData, setStudentData] = useState({});
  const [searchId, setSearchId] = useState("");


  const addRow = () => {
    setRows([...rows, ["", "", "", "", "", ""]]);
  };

  // Get today's date in the desired format
  const today = new Date();
  const formattedDate = today.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });

  const handleSearchChange = (event) => {
    setSearchId(event.target.value); // Update search input
  };

  const handleSave = async () => {
    const transactions = rows.map((row) => ({
      transactionid: row[0],
      description: row[1],
      total: row[3],
      balance: row[4],
      amountreceived: row[5],
      subtotal: row[5],
    }));

    const paymentData = {
      studentid: searchId, // dynamic student ID
      name: "ttt", // dynamic name
      address: "gfffhghgs", // dynamic address
      phone: "45678ss9", // dynamic phone number

      receiptno: "112444", // Replace with dynamic value if necessary
      usdrate: "86", // Replace with dynamic value if necessary
      transaction: transactions,
    };

    try {
      const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                throw new Error("No accounts found");
            }
            msalInstance.setActiveAccount(accounts[0]);
      const token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"]
    });
      await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/hive/hive`, paymentData, {
        headers: {
          Authorization: `Bearer ${token.idToken}`
        }
      });
      message.success("Payment Receipt Saved Successfully!");
    } catch (error) {
      console.error("Error saving payment receipt", error);
      message.error("Failed to save payment receipt!");
      console.log("message", paymentData)
    }
  };

  // Fetch student data based on the search ID
  useEffect(() => {
    const fetchStudentData = async () => {
      if (searchId) {
        const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                throw new Error("No accounts found");
            }
            msalInstance.setActiveAccount(accounts[0]);
        const token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"]
        });
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/v1/student/${searchId}`, {
            headers: {
              Authorization: `Bearer ${token.idToken}`
            }
          })
          .then((response) => {
            setStudentData(response.data);
          })
          .catch((error) => {
            console.error("Error fetching student data", error);
            setStudentData({});
          });
      }
    };

    fetchStudentData();
  }, [searchId]);

  const calculateReceivedAmount = (total,amountReceived ) => {
    return total - amountReceived;
  };

  const calculateSubtotal = () => {
    return rows.reduce((subtotal, row) => {
      const totalAmount = parseFloat(row[3]) || 0;
      const amountReceived = parseFloat(row[5]) || 0;
      return subtotal + (totalAmount - amountReceived);
    }, 0);
  };

  const handleInputChange = (rowIndex, columnIndex, value) => {
    const updatedRows = [...rows];
    updatedRows[rowIndex][columnIndex] = value;

    // If "INR Received" is updated (columnIndex 5), also copy the value to "Amount Received"
    if (columnIndex === 5) {
      const totalAmount = parseFloat(updatedRows[rowIndex][3]) || 0;
    const amountReceived = parseFloat(value) || 0;
    updatedRows[rowIndex][4] = calculateReceivedAmount(totalAmount, amountReceived).toFixed(2); // Balance
    }

    setRows(updatedRows);
  };

  return (
    <div className="d-flex">
      {/* Left Sidebar */}
      <div
        className="p-4 d-flex flex-column justify-content-between"
        style={{
          background: "#ACB9CA",
          width: "230px",
        }}
      >
        {/* Logo and Tagline */}
        <div className="text-center">
          <img src={" "} alt="Hive Logo" className="img-fluid mb-3" />
          <p
            style={{
              fontFamily: "Futura Bk BT",
              fontSize: "12px",
              fontStyle: "italic",
              color: "#333333",
            }}
          >
            ES HIVE Educational Consultancy (P) Ltd.
          </p>
        </div>

        {/* Company Details */}
        <div
          style={{
            fontFamily: "Futura Bk BT",
            fontSize: "10px",
            fontWeight: 400,
            color: "#333333",
          }}
        >
          <p
            style={{
              fontSize: "10px",
              textAlign: "left",
            }}
          >
            5/259A, 2ND FLOOR, NV ARCADE BUILDING, JUNCTION MAIN ROAD, NEAR 5
            ROADS, NEXT TO RELIANCE MALL, SALEM - 636 004.
          </p>
          <p>Mail: hiveeduco.sim@gmail.com</p>
          <p>Ph.no: 9488926736 / 8838461064</p>
          <p>THANK YOU FOR YOUR BUSINESS</p>
        </div>
      </div>

      {/* Right Content Section */}
      <div className="bg-white p-4 shadow-sm" style={{ width: "100%" }}>
        <h5
          className="mb-4"
          style={{
            fontFamily: "Futura Bk BT",
            fontSize: "46px",
            fontWeight: 400,
            lineHeight: "55.14px",
            textAlign: "left",
            textUnderlinePosition: "from-font",
            textDecorationSkipInk: "none",
          }}
        >
          Payment Receipt
        </h5>

        <hr />

        {/* Header Details */}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div>
            <p>
              <strong>DATE:</strong> {formattedDate}
            </p>
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <div>
                <label className="me-2"><strong>Receipt No:</strong></label>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Receipt Number"
                  style={{ width: "200px" }}
                />
              </div>
            </div>
          </div>

          <div>
            <strong className="text-center mb-4">Student Details</strong>
            {/* Student ID Field */}
            <div className="mb-3 d-flex justify-content-between">
              <div>
                <label className="me-2">Student ID:</label>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Student ID"
                  style={{ width: "200px" }}
                />
              </div>
            </div>

            <div className="mb-3 d-flex justify-content-between">
              <div>
                <label className="me-2">Name:</label>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  style={{ width: "200px" }}
                />
              </div>
            </div>

            {/* Address Field */}
            <div className="mb-3 d-flex justify-content-between">
              <div>
                <label className="me-2">Address:</label>
              </div>
              <div>
                <textarea
                  className="form-control"
                  placeholder="Enter Address"
                  style={{ width: "200px" }}
                />
              </div>
            </div>

            {/* Phone Number Field */}
            <div className="mb-3 d-flex justify-content-between">
              <div>
                <label className="me-2">Phone Number:</label>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Phone Number"
                  style={{ width: "200px" }}
                />
              </div>
            </div>
          </div>
        </div>

        <hr />

        {/* Payment Information */}
        <div className="row mb-3">
          <div className="col-md-3">
            <label>
              <strong>Payment Method</strong>
            </label>
            <select className="form-control">
              <option>Cash</option>
              <option>Credit Card</option>
              <option>Debit Card</option>
              <option>UPI</option>
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <strong>INR Received</strong>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter INR Amount"
              value={rows[0][5]} // Adjust as needed for initial row
              onChange={(e) => handleInputChange(0, 5, e.target.value)}
            />
          </div>
          <div className="col-md-3">
            <label>
              <strong>Transaction Date</strong>
            </label>
            <input type="date" className="form-control" />
          </div>
          <div className="col-md-3">
            <label>
              <strong>USD Rate</strong>
            </label>
            <div>
              <input
                type="text"
                className="form-control"
                placeholder="Enter USD Rate"
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </div>

        {/* Payment Table */}
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ backgroundColor: "#D5A97A" }}>S.No</th>
              <th style={{ backgroundColor: "#D5A97A" }}>Transaction ID</th>
              <th style={{ backgroundColor: "#D5A97A" }}>Description</th>
              <th style={{ backgroundColor: "#D5A97A" }}>Total</th>
              <th style={{ backgroundColor: "#D5A97A" }}>Balance</th>
              <th style={{ backgroundColor: "#D5A97A" }}>Amount Received</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={row[0]}
                    onChange={(e) => handleInputChange(index, 0, e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={row[1]}
                    onChange={(e) => handleInputChange(index, 1, e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={row[3]}
                    onChange={(e) => handleInputChange(index, 3, e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={row[4]}
                    onChange={(e) => handleInputChange(index, 4, e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={row[5]}
                    onChange={(e) => handleInputChange(index, 5, e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="d-flex justify-content-end mb-3 mt-1" >
          <button className="btn" onClick={addRow} style={{ backgroundColor: "#D5A97A" }}>
            Add Row
          </button>
        </div>
        <div className="d-flex flex-column align-items-end">
          <div className="d-flex mb-2">
            <strong className="me-2">Sub Total: </strong>
            <input
              type="text"
              value={rows[0][5]} // Adjust as needed for initial row
              onChange={(e) => handleInputChange(0, 5, e.target.value)}
              readOnly
            />
          </div>
          <div className="d-flex">
            <strong className="me-2">Total</strong>
            <input
              type="text"
              value={rows[0][5]} // Adjust as needed for initial row
              onChange={(e) => handleInputChange(0, 5, e.target.value)}
              readOnly
            />
          </div>
        </div>

        {/* Save Button */}
        <div className="d-flex justify-content-end mt-3">
          <button className="btn btn-success" onClick={handleSave}>
            Save Receipt
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentReceipt;
