import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Next from '../Assets/Next.png'
import Previous from '../Assets/Previous.png'
import Message from '../Assets/message.png';
import Profile from '../Assets/profile.png';


function Admin() {

    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        // Set selectedDate to the current date on component mount
        setSelectedDate(new Date());
    }, []);

    const getMonthName = (monthIndex) => {
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
        ];
        return months[monthIndex];
    };

    const getDaysOfWeek = () => ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

    const generateDays = () => {
        const month = currentDate.getMonth();
        const year = currentDate.getFullYear();
        const firstDayOfMonth = new Date(year, month, 1).getDay();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const daysArray = [];


        for (let i = 0; i < firstDayOfMonth; i++) {
            daysArray.push(null);
        }
        for (let day = 1; day <= daysInMonth; day++) {
            daysArray.push(day);
        }
        while (daysArray.length % 7 !== 0) {
            daysArray.push(null);
        }
        return daysArray;
    };

    const nextMonth = () => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() + 1)));
    const prevMonth = () => setCurrentDate(new Date(currentDate.setMonth(currentDate.getMonth() - 1)));

    const handleDateClick = (day) => {
        if (day) {
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth();
            setSelectedDate(new Date(year, month, day));
        }
    };

    const daysArray = generateDays();

    return (
        <div className="container-fluid min-vh-100 d-flex flex-column align-items-start" style={{ backgroundColor: '#1E1E2E' }}>
            <nav
                style={{
                    "--bs-breadcrumb-divider": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E")`,
                    fontSize: '1.5rem',
                    fontWeight: 500,
                    backgroundColor: '#1E1E2E'
                }}
                aria-label="breadcrumb"
                className="p-3 w-100"
            >
                <ol className="breadcrumb m-2">
                    <li className="breadcrumb-item" style={{ color: '#EEEEEE' }}>Admin Profile</li>
                    <li className="breadcrumb-item active" aria-current="page"></li>
                </ol>
            </nav>
            <div className="row w-100 justify-content-center mb-3">
                <div className="col-lg-7">
                    <div
                        style={{
                            backgroundColor: 'rgba(37, 40, 54, 1)',
                            borderRadius: '8px',
                            padding: '25px',
                        }}
                        className="d-flex align-items-center w-100"
                    >
                        <img
                            src={Profile}
                            alt="Profile"
                            className="me-4"
                            style={{ width: '120px', height: '120px', borderRadius: '50%' }}
                        />
                        <div className="d-flex justify-content-between w-100 align-items-center">
                            <div className="d-flex flex-column align-items-start">
                                <h5 className="mb-0" style={{ color: '#EEEEEE' }}>Kennin</h5>
                                <h6 className="mb-0" style={{ color: '#EEEEEE' }}>Office staff</h6>
                                <p className="mb-0" style={{ color: '#E0E0E0' }}>Chennai, India</p>
                            </div>
                            <div className="d-flex flex-column align-items-start">
                                <p className="mb-0" style={{ color: '#AAAAAA' }}>Employee ID</p>
                                <p className="mb-0" style={{ color: '#E0E0E0' }}>EMP2024AD23</p>
                                <button className="btn mt-1" style={{ backgroundColor: '#2D2F3A', color: '#AAAAAA' }}>Edit Layout</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div
                        style={{
                            backgroundColor: 'rgba(37, 40, 54, 1)',
                            color: '#EEEEEE',
                            borderRadius: '8px',
                            padding: '18px',
                        }}
                        className="w-100"
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>New Events</h5>
                            <a href="#" className="text-decoration-none" style={{ color: '#AAAAAA' }}>View More &gt;</a>
                        </div>
                        <div className="d-flex mt-4 p-2 rounded" style={{ backgroundColor: '#2D2F3A', width: '100%' }}>
                            <img src={Message} alt="Profile" className="rounded-circle me-4" style={{ width: '50px', height: '50px' }} />
                            <div className="d-flex justify-content-between w-100">
                                <div className="d-flex flex-column align-items-start">
                                    <h6 style={{ color: 'rgba(228, 228, 231, 1)' }}>New Message</h6>
                                    <p>Description</p>
                                </div>
                                <div className="d-flex flex-column align-items-start">
                                    <span style={{ color: '#AAAAAA' }}>10:30 AM</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: 'rgba(37, 40, 54, 1)',
                    color: 'rgba(228, 228, 231, 1)',
                    borderRadius: '8px',
                }}
                className="col-md-12 d-flex align-items-start w-100 p-4"
            >
                <div className=" d-flex flex-column w-75 w-md-50 w-lg-50 p-2 mx-auto">
                    <div
                        style={{
                            backgroundColor: 'rgba(47, 50, 64, 1)',
                            color: 'rgba(228, 228, 231, 1)',
                            borderRadius: '8px',
                            padding: '30px',
                        }}
                        className="personal-details-container"
                    >
                        <div className=' d-flex justify-content-between align-items-center'>
                            <h6>Personal Details</h6>
                            <h6><a href="#" className="text-decoration-none" style={{ color: '#AAAAAA' }} data-bs-toggle="modal" data-bs-target="#exampleModalToggle">Edit</a></h6>
                        </div>
                        <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                            <div className="modal-dialog modal-dialog-centered" >
                                <div className="modal-content" style={{ backgroundColor: 'rgba(47, 50, 64, 1)' }}>
                                    <div className="m-3">
                                        <h3 className="modal-title fs-5" id="exampleModalToggleLabel">Personal Details</h3>
                                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                                    </div>
                                    <div className="p-3">
                                        <form>
                                            <div className="row justify-content-around">
                                                <div className="col-md-5 mb-3 d-flex row justify-content-start">
                                                    <label htmlFor="phoneNumber" className="form-label me-2" style={{ width: '70%' }}>
                                                        Phone
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="phoneNumber"
                                                        style={{ backgroundColor: '#3A3F51', color: 'white' }}
                                                    />
                                                </div>
                                                <div className="col-md-5 mb-3 d-flex row justify-content-start">
                                                    <label htmlFor="lastName" className="form-label me-2" style={{ width: '70%' }}>
                                                        Last Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="lastName"
                                                        style={{ backgroundColor: '#3A3F51', color: 'white' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row justify-content-around">
                                                <div className="col-md-5 mb-3 d-flex row justify-content-start">
                                                    <label htmlFor="street" className="form-label me-2" style={{ width: '70%' }}>
                                                        Street
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="street"
                                                        style={{ backgroundColor: '#3A3F51', color: 'white' }}
                                                    />
                                                </div>
                                                <div className="col-md-5 mb-3 d-flex row justify-content-start">
                                                    <label htmlFor="cityState" className="form-label me-2" style={{ width: '70%' }}>
                                                        City/State
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="cityState"
                                                        style={{ backgroundColor: '#3A3F51', color: 'white' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row justify-content-around">
                                                <div className="col-md-5 mb-3 d-flex row justify-content-start">
                                                    <label htmlFor="country" className="form-label me-2" style={{ width: '70%' }}>
                                                        Country
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="country"
                                                        style={{ backgroundColor: '#3A3F51', color: 'white' }}
                                                    />
                                                </div>
                                                <div className="col-md-5 mb-3 d-flex row justify-content-start">
                                                    <label htmlFor="zipCode" className="form-label me-2" style={{ width: '70%' }}>
                                                        Zip Code
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="zipCode"
                                                        style={{ backgroundColor: '#3A3F51', color: 'white' }}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="d-flex justify-content-end gap-3" // Align buttons to the right with gap in between
                                                style={{ padding: '20px 0' }} // Optional: Add some padding if needed
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=' d-flex justify-content-between'>
                            <div className='p-4'>
                                <div className=' mb-2 d-flex row justify-content-start align-items-center' >
                                    <p className='text-decoration-underline mb-1' style={{ color: '#AAAAAA' }}>First Name</p>
                                    <p >Kennin</p>
                                </div>
                                <div className=' mb-2 d-flex row justify-content-start align-items-center' >
                                    <p className='text-decoration-underline mb-1' style={{ color: '#AAAAAA' }}>Email Address</p>
                                    <p>kenchar@company.com</p>
                                </div>
                                <div className='d-flex row justify-content-start align-items-center' >
                                    <p className='text-decoration-underline mb-1' style={{ color: '#AAAAAA' }}>Position</p>
                                    <p>Administrator</p>
                                </div>
                            </div>
                            <div className='p-4'>
                                <div className=' mb-2 d-flex row justify-content-end' >
                                    <p className='text-decoration-underline mb-1' style={{ color: '#AAAAAA' }}>Last Name</p>
                                    <p>Charles</p>
                                </div>
                                <div className=' mb-2 d-flex row justify-content-end' >
                                    <p className='text-decoration-underline mb-1' style={{ color: '#AAAAAA' }}>Phone</p>
                                    <p>+91 789 4561 230</p>
                                </div>
                                <div className=' mb-2 d-flex row justify-content-end' >
                                    <p className='text-decoration-underline mb-1' style={{ color: '#AAAAAA' }}>Joining Date</p>
                                    <p>07th Jan 2022</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h6>Address</h6>
                        </div>
                        <div className=' d-flex justify-content-between'>
                            <div className='p-4'>
                                <div className=' mb-2 d-flex row justify-content-start align-items-center' >
                                    <p className='text-decoration-underline mb-1' style={{ color: '#AAAAAA' }}>Street</p>
                                    <p>Bharathiyar str</p>
                                </div>
                                <div className=' mb-2 d-flex row justify-content-start align-items-center' >
                                    <p className='text-decoration-underline mb-1' style={{ color: '#AAAAAA' }}>Country</p>
                                    <p>India</p>
                                </div>
                            </div>
                            <div className='p-4'>
                                <div className=' mb-2 d-flex row justify-content-end' >
                                    <p className='text-decoration-underline mb-1' style={{ color: '#AAAAAA' }}>City/State</p>
                                    <p>Chennai, TamilNadu</p>
                                </div>
                                <div className=' d-flex row justify-content-end' >
                                    <p className='text-decoration-underline mb-1' style={{ color: '#AAAAAA' }}>Zip Code</p>
                                    <p>600 012</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="d-flex flex-column w-50 p-2 gap-2">
                    <div
                        style={{
                            backgroundColor: 'rgba(47, 50, 64, 1)',
                            color: 'rgba(228, 228, 231, 1)',
                            borderRadius: '8px',
                            padding: '15px',
                            marginBottom: '8px',
                        }}
                        className="calendar-days-container"
                    >
                        <div className="d-flex justify-content-between align-items-center m-2">
                            <h2 className="m-0 text-light" >{getMonthName(currentDate.getMonth())} {currentDate.getFullYear()}</h2>
                            <div className="d-flex justify-content-between align-items-center">
                                <button className="btn" style={{ backgroundColor: 'transparent' }} onClick={prevMonth}><img src={Previous} /></button>
                                <button className="btn" style={{ backgroundColor: 'transparent' }} onClick={nextMonth}><img src={Next} /></button>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            backgroundColor: 'rgba(47, 50, 64, 1)',
                            color: 'rgba(228, 228, 231, 1)',
                            borderRadius: '8px',
                            padding: '26px',
                        }}
                        className="calendar-month-swap-container"
                    >
                        <div className="row text-center font-weight-bold text-light mb-4 mt-4" style={{ backgroundColor: 'transparent' }}> {/* Added background color for days */}
                            {getDaysOfWeek().map((day, index) => (
                                <div key={index} className="col p-1">{day}</div>
                            ))}
                        </div>
                        {Array.from({ length: daysArray.length / 7 }).map((_, weekIndex) => (
                            <div className="row text-center mb-4" key={weekIndex} >
                                {daysArray.slice(weekIndex * 7, weekIndex * 7 + 7).map((day, index) => (
                                    <div key={index} className="col p-1" >
                                        {day ? (
                                            <div
                                                className={`p-1 rounded-circle  ${selectedDate && selectedDate.getDate() === day && selectedDate.getMonth() === currentDate.getMonth() && selectedDate.getFullYear() === currentDate.getFullYear() ? 'bg-primary' : 'bg-transparent'}`}
                                                onClick={() => handleDateClick(day)}
                                                style={{ cursor: 'pointer', fontSize: '1rem' }}
                                            >
                                                {day}
                                            </div>
                                        ) : (
                                            <div className="p-0"></div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Admin;
