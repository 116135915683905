import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Classes from "./StudentData.module.css";
import axios from "axios";
import Profile from '../Assets/profile.png'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import download from '../Assets/download.png';
import resize from '../Assets/resize.png';
import print from '../Assets/print.png';
import * as XLSX from "xlsx"; // Import xlsx library   
import msalInstance from "../msalConfig";

const StudentData = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate(); // Initialize useNavigate
  const [searchQuery, setSearchQuery] = useState(""); // Search query state
  useEffect(() => {
    const fetchData = async () => {
      // Fetch student data from the API
     
      const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                throw new Error("No accounts found");
            }
            msalInstance.setActiveAccount(accounts[0]);
      const token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"]
      });
      axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent`, {
        headers: {
          Authorization: `Bearer ${token.idToken}`
        }
      })
        .then(response => {
          setData(response.data);
          console.log(response.data); // Assuming API response is an array of students
        })
        .catch(error => {
          console.error("Error fetching student data:", error);
        });
    };

    fetchData();
  }, []);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleViewProfile = (studentid) => {
    navigate(`/app/studentprofile/${studentid}`);
    // Navigate to profile page logic here, e.g., `navigate(`/profile/${id}`)`
  };




const handleDeleteRow = async (studentId) => {
  if (window.confirm("Are you sure you want to delete this student?")) {
    try {
      // Fetch account details
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        throw new Error("No accounts found");
      }
      msalInstance.setActiveAccount(accounts[0]);

      // Acquire token silently
      const token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"],
      });

      // Send the DELETE request to the API
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${studentId}`, {
        headers: {
          Authorization: `Bearer ${token.idToken}`,
        },
      });

      // Update the local state to reflect the deletion
      const updatedData = data.filter((item) => item.studentid !== studentId);
      setData(updatedData);

      alert("Student deleted successfully!");
    } catch (error) {
      console.error("Error deleting student:", error.response || error.message);
      alert("Failed to delete student. Please try again.");
    }
  }
};

  

  const handleToggleMenu = (id) => {
    setSelectedRow(selectedRow === id ? null : id); // Toggle menu visibility
  };

  const totalItems = 100; // Example total number of items (you can calculate this dynamically)
  const itemsPerPage = 10; // Number of items per page

  const totalPages = Math.ceil(totalItems / itemsPerPage); // Calculate total pages
  const currentPages = 1; // Current page (this could come from state)
  const range = [];
  const maxPagesToShow = 5; // Number of pages to show at once

  for (let i = 1; i <= totalPages; i++) {
    if (
      i <= maxPagesToShow ||
      i >= totalPages - maxPagesToShow + 1 ||
      (i >= currentPages - 1 && i <= currentPages + 1)
    ) {
      range.push(i);
    } else if (range[range.length - 1] !== '...') {
      range.push('...');
    }
  }
 

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filters data based on the search query
  const filteredData = data.filter(
    (item) =>
      item.studentid.toString().toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.firstname.toLowerCase().includes(searchQuery.toLowerCase())
  );


// Handles downloading the page as an HTML file
const handleDownloadExcel = () => {
  const worksheet = XLSX.utils.json_to_sheet(filteredData); // Convert data to worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Student Data");
  XLSX.writeFile(workbook, "StudentData.xlsx"); // Save file as StudentData.xlsx
  };

  return (
    <Container
      fluid
      className=" p-4"
      style={{ backgroundColor: "#1E1E2E", minHeight: "210vh" }}
    >
      <nav
        style={{
          "--bs-breadcrumb-divider": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E")`,
          fontSize: "1.5rem",
          fontWeight: 500,
          backgroundColor: "#1E1E2E",
        }}
        aria-label="breadcrumb"
        className="text-white d-flex justify-content-between align-items-center"
      >
        <ol className="breadcrumb ">
          <li className="breadcrumb-item text-white">Student Data</li>
          <li className="breadcrumb-item text-white"></li>
        </ol>
        <div className="d-flex align-items-center gap-3">
          {/* Search Bar */}
          <input
            type="text"
            className="form-control custom-placeholder"
            placeholder="Search"
            style={{
              width: "200px",
              backgroundColor: "#2C2C3C",
              border: "1px solid #444",
              color: "#FFFFFF",
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <style>
            {`
              .custom-placeholder::placeholder {
              color: rgba(255, 255, 255, 0.7); /* Light gray placeholder */
              }
            `}
          </style>
          {/* Custom Icons */}
          <img
            src={print}
            alt="Print"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
            title="Print"
            onClick={() => window.print()}
          />
          <img
            src={download}
            alt="Download"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
            title="Download"
            onClick={handleDownloadExcel}
          />
          <img
            src={resize}
            alt="Fullscreen"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
            title="Fullscreen"
            onClick={() => document.documentElement.requestFullscreen()}
          />
        </div>

      </nav>
      <Row>
        <Col>
          <Card style={{ borderColor: '#3E3E3E' }}>
            <Card.Body className='p-0' style={{ backgroundColor: '#2D2F3A' }}>
              <table className="text-light" style={{ borderCollapse: 'collapse', border: 'none', width: '100%' }}>
                <thead>
                  <tr>
                    <td className={ `text-start `} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "15%", fontWeight: 500, fontSize: '14px' }}>ID Number</td>
                    <td className={`text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "10%", fontWeight: 500, fontSize: '14px' }}>Name</td>
                    <td className={` text-start `} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "10%", fontWeight: 500, fontSize: '14px' }}>Gender</td>
                    <td className={` text-start `} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "5%", fontWeight: 500, fontSize: '14px' }}></td>
                    <td className={` text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "15%", fontWeight: 500, fontSize: '14px' }}>Passport No.</td>
                    <td className={` text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "20%", fontWeight: 500, fontSize: '14px' }}>Date of Birth</td>
                    <td className={` text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "25%", fontWeight: 500, fontSize: '14px' }}>year</td>
                    <td className={` text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "20%", fontWeight: 500, fontSize: '14px' }}>Nationality</td>
                    <td className={` text-start`} style={{ backgroundColor: '#3A3F51', color: '#A0A0A0', padding: '10px', width: "5%" }}></td>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map((item, index) => (
                    <tr key={index} style={{ borderBottom: "1px solid #3E3E3E" }}>
                      <td className="text-start" style={{ padding: '10px', color: '#EEEEEE' }}>{item.studentid}</td>
                      <td className="text-start" style={{ padding: '10px', color: '#EEEEEE' }}>{item.firstname}</td>
                      <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.gender}</td>
                      <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.age}</td>
                      <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.passportnumber}</td>
                      <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.dateofbirth}</td>
                      <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.year}</td>
                      <td className="text-start" style={{ padding: '10px', color: '#A0A0A0' }}>{item.nationality}</td>
                      <td className="text-center" style={{ padding: '10px', color: '#A0A0A0', position: 'relative' }}>
                        <Button
                          variant="link"
                          className="p-0"
                          style={{ color: '#A0A0A0', cursor: 'pointer', textDecoration: 'none' }}
                          onClick={() => handleToggleMenu(item.studentid)}
                        >
                          ...
                        </Button>
                        {selectedRow === item.studentid && (
                          <div
                            style={{
                              position: 'absolute',
                              top: '100%',
                              left: -150,
                              backgroundColor: '#1E1E2E',
                              borderRadius: '8px',
                              padding: '2px',
                              zIndex: 1,
                              width: '150px',
                              display: 'table'
                            }}
                          >
                            <div style={{ display: 'table-row', borderBottom: '1px solid #3E3E3E' }}>
                              <div
                                onClick={() => handleViewProfile(item.studentid)}
                                style={{ color: '#EEEEEE', padding: '5px 10px', cursor: 'pointer', }}
                              >
                                <img src={Profile} alt="profile-img" style={{ marginRight: '8px', width: '30px', height: '30px' }}></img>View Profile
                              </div>
                            </div>
                            <div style={{ display: 'table-row' }}>
              <div
                onClick={() => handleDeleteRow(item.studentid)}
                style={{ display: 'flex', justifyContent: 'center', color: '#EEEEEE', padding: '5px 10px', cursor: 'pointer', alignItems: 'start' }}
              >
                <i className="bi bi-trash" style={{ marginRight: '8px', color: '#FF0000' }}></i>Delete
              </div>
            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
          {/* Pagination controls */}
          <div className="row m-4">
            <div className="col">
              <div className="d-flex justify-content-between" >
                <Button
                  variant="outline-primary"
                  className="me-3"
                  style={{ color: "#EEEEEE", backgroundColor: '#3A3F51', border: 'none' }}
                  onClick={() => setCurrentPage(currentPages > 1 ? currentPages - 1 : 1)}
                >
                 <FaArrowLeft /> Previous
                </Button>
                {/* Page numbers could be dynamically rendered */}
                <div className="d-flex justify-content-center">
                  {range.map((page, index) => (
                    <Button
                      key={index}
                      variant="outline-primary"
                      className="me-3"
                      style={{
                        color: currentPage === page ? "#ffffff" : "#757575",
                        backgroundColor: currentPage === page ? "#3A3F51" : "transparent",
                        border: "none",
                      }}
                      onClick={() => setCurrentPage(page)}
                      disabled={page === '...'}
                    >
                      {page}
                    </Button>
                  ))}
                </div>
                <Button
                  variant="outline-primary"
                  className="me-3"
                  style={{ border: "none", color: "#EEEEEE", backgroundColor: '#3A3F51' }}
                  onClick={() => setCurrentPage(currentPage < 8 ? currentPage + 1 : 8)}
                >
                   Next <FaArrowRight />
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StudentData;