import React from "react";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';  // This is needed for offcanvas functionality
import { RouterProvider } from "react-router-dom";
import LoginPage from "./Components/LoginPage";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Profile from "./Components/Authenticate";
import Dashboard from "./Components/Dashboard";
import Navbar from "../src/Components/Navbar";
import StudentData from "../src/Components/StudentData";
import AddStudent from "./Components/AddStudent";
import FeePayment from './Components/Billings'
import Admin from './Components/admin'
import StudentProfile from "../src/Components/StudentProfile";
import { createBrowserRouter } from "react-router-dom";
import Receipt from "./Components/Receipt";
import SettingsPanel from "./Components/SettingsPanel";
import Signup from "./Components/Signup";
// import ApprovalPage from "./Components/approvel";
import ViewProfile from '../src/Components/ViewProfile'
import EsaraPayment from "./Components/EsaraPayment";
import Hive from  '../src/Components/HiveReceipt'
import BillingRecipt from "./Components/BillingRecipt";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { MsalRedirectComponent } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";

const AppRouter = () => {
  const isAuthenticated = useIsAuthenticated();

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthenticatedTemplate>
      <Navigate to="/app/dash" replace />
  </AuthenticatedTemplate>
      

    },
    {
      path: "signup",
      element: <Signup />,
    },
    {
      path: "/app",
      element: <Navbar />,
      children: [
        {
          path: "dash",
          element: <Dashboard />,
        },
        {
          path: "studentdata",
          element: <StudentData />,
        },
        {
          path: "studentprofile/:studentid",
          element: <StudentProfile />,
        },
        {
          path: "studentprofile",
          element: <ViewProfile />,
        },
        {
          path: "addstudent",
          element: <AddStudent />,
        },
        {
          path: "feepayment",
          element: <FeePayment />,
        },
        {
          path: "settingspanel",
          element: <SettingsPanel />,
        },
        {
          path: "receipt",
          element: <Receipt />,
        },
        {
          path: "admin",
          element: <Admin />,
        },
        // {
        //   path: "approvel",
        //   element: <ApprovalPage />,
        // },
        {
          path: "esarapayment/",  
          element: <EsaraPayment/>
        },
        {
          path: "hivereceipt",
          element: <Hive />,
        },
        {
          path: "billing-receipt/:studentid",
          element: <BillingRecipt />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

function App() {
  return (
    <div>
      <AuthenticatedTemplate>
        <AppRouter />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;