import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register chart elements
ChartJS.register(ArcElement, Tooltip, Legend);

const SettingsPanel = () => {
  const [isDarkMode, setIsDarkMode] = useState(false); // State to manage dark mode

  const data = {
    labels: ["Red", "Green", "Blue"],
    datasets: [
      {
        data: [300, 50, 100],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  // Toggle dark mode
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  return (
    <div
      className="settings p-3"
      style={{
        backgroundColor: isDarkMode ? "#2d2d3d" : "#ffffff", // Dark mode background
        color: isDarkMode ? "#ffffff" : "#000000", // Light mode text color
        width: "100%", // Make sure it takes the full width
        height: "auto", // Adjust height to fit content
        maxHeight: "90vh", // Ensures the panel height fits in the screen
        overflowX: "hidden", // Remove horizontal scroll
        overflowY: "auto", // Allow vertical scrolling if necessary
        boxShadow: isDarkMode ? "0 4px 8px rgba(0,0,0,0.2)" : "none", // Add box shadow for dark mode
        borderRadius: "8px", // Optionally, you can add rounded corners
      }}
    >
      <h6>Internal Settings</h6>
      <div style={{ height: "200px", width: "100%", margin: "0 auto" }}>
        <Doughnut
          data={data}
          options={{
            maintainAspectRatio: false,
            responsive: true,
          }}
        />
      </div>

      <p className="mt-2">Users Online: 250</p>

      <div className="d-flex justify-content-between align-items-center">
        <span>Notification</span>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
          />
        </div>
      </div>

      {/* Dark Mode Toggle Button */}
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <span>Dark Mode</span>
        <button
          onClick={toggleDarkMode}
          className={`btn ${isDarkMode ? "btn-light" : "btn-dark"}`}
          style={{
            padding: "5px 15px",
            backgroundColor: isDarkMode ? "#ffffff" : "#333",
            color: isDarkMode ? "#333" : "#fff",
          }}
        >
          {isDarkMode ? "Light Mode" : "Dark Mode"}
        </button>
      </div>

      <h6 className="mt-4">Last Updates</h6>
      <ul className="list-unstyled">
        {["Title 1", "Title 2", "Title 3"].map((title, index) => (
          <li
            key={index}
            className="d-flex align-items-center p-2 mb-2"
            style={{
              backgroundColor: isDarkMode ? "#3b3b4d" : "#f9f9f9",
              borderRadius: "8px",
            }}
          >
            <div
              className="me-2"
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#f97316",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>I</span>
            </div>
            <div className="flex-grow-1">
              <strong>{title}</strong>
              <div className="text-muted small">Description</div>
            </div>
            <span className="small text-muted">09:42 AM</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SettingsPanel;
