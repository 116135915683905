import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Profile from "../Assets/profile.png";
import Classes from "./Receipt.module.css";
import download from '../Assets/download.png';
import resize from '../Assets/resize.png';
import print from '../Assets/print.png';
import view from '../Assets/_Button base.png'
import * as XLSX from "xlsx"; // Import xlsx library
import BillingRecipt from "./BillingRecipt";
import msalInstance from "../msalConfig";

const StudentData = () => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(""); // Search query state

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                throw new Error("No accounts found");
            }
            msalInstance.setActiveAccount(accounts[0]);
        const token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"]
        });
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/populateStudent`, {
          headers: {
            Authorization: `Bearer ${token.idToken}`
          }
        });
        if (Array.isArray(response.data)) {
          setData(response.data);
          console.log(response.data);
        } else {
          console.error("Unexpected API response format:", response.data);
          setData([]);
        }
      } catch (err) {
        console.error("Error fetching student data:", err);
      }
    };

    fetchData();
  }, []);


  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleImageClick = (studentid) => {
    navigate(`/app/billing-receipt/${studentid}`);
  };


  // Filters data based on the search query
  const filteredData = data.filter((item) => {
    const transactionId = item.transaction_id ? item.transaction_id.toString().toLowerCase() : '';
    const transactionType = item.transaction_type ? item.transaction_type.toString().toLowerCase() : '';
    const firstName = item.student_details?.firstname ? item.student_details.firstname.toLowerCase() : '';
  
    return (
      transactionId.includes(searchQuery.toLowerCase()) ||
      transactionType.includes(searchQuery.toLowerCase()) ||
      firstName.includes(searchQuery.toLowerCase())
    );
  });

    const handleToggleMenu = (id) => {
    setSelectedRow(selectedRow === id ? null : id); // Toggle menu visibility
  };

  const totalItems = 100; // Example total number of items (you can calculate this dynamically)
  const itemsPerPage = 10; // Number of items per page

  const totalPages = Math.ceil(totalItems / itemsPerPage); // Calculate total pages
  const currentPages = 1; // Current page (this could come from state)
  const range = [];
  const maxPagesToShow = 5; // Number of pages to show at once

  for (let i = 1; i <= totalPages; i++) {
    if (
      i <= maxPagesToShow ||
      i >= totalPages - maxPagesToShow + 1 ||
      (i >= currentPages - 1 && i <= currentPages + 1)
    ) {
      range.push(i);
    } else if (range[range.length - 1] !== '...') {
      range.push('...');
    }
  }

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData); // Convert data to worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Student Data");
    XLSX.writeFile(workbook, "StudentData.xlsx"); // Save file as StudentData.xlsx
  };

  return (
    <Container
      fluid
      className=" p-4"
      style={{ backgroundColor: "#1E1E2E", minHeight: "210vh" }}
    >
      <nav
        aria-label="breadcrumb"
        className="text-white d-flex justify-content-between align-items-center"
        style={{
          fontSize: "1.5rem",
          fontWeight: 500,
          backgroundColor: "#1E1E2E",
        }}
      >
        <ol className="breadcrumb">
          <li className="breadcrumb-item text-white">Accounts</li>
          <li className="breadcrumb-item text-white"></li>

        </ol>
        <div className="d-flex align-items-center gap-3">
          {/* Search Bar */}
          <input
            type="text"
            className="form-control custom-placeholder"
            placeholder="Search"
            style={{
              width: "200px",
              backgroundColor: "#2C2C3C",
              border: "1px solid #444",
              color: "#FFFFFF",
            }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <style>
            {`
              .custom-placeholder::placeholder {
              color: rgba(255, 255, 255, 0.7); /* Light gray placeholder */
              }
            `}
          </style>
          {/* Custom Icons */}
          <img
            src={print}
            alt="Print"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
            title="Print"
            onClick={() => window.print()}
          />
          <img
            src={download}
            alt="Download"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
            title="Download"
            onClick={handleDownloadExcel}
          />
          <img
            src={resize}
            alt="Fullscreen"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
            title="Fullscreen"
            onClick={() => document.documentElement.requestFullscreen()}
          />
        </div>
      </nav>
      <Row>
        <Col>
          <Card style={{ borderColor: "#3E3E3E" }}>
            <Card.Body
              className="p-0"
              style={{ backgroundColor: "#2D2F3A" }}
            >
              <table
                className="text-light"
                style={{
                  borderCollapse: "collapse",
                  border: "none",
                  width: "100%",
                }}
              >
                <thead style={{ backgroundColor: '#3A3F51' }}>
                  <tr>
                    <td
                      className={`${Classes.table} text-start`}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#A0A0A0",
                        padding: "10px",
                      }}
                    >
                      Transaction ID
                    </td>
                    <td
                      className={`${Classes.table} text-start`}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#A0A0A0",
                        padding: "10px",
                        fontWeight: 500,
                      }}
                    >
                      Name
                    </td>
                    <td
                      className={`${Classes.table} text-start`}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#A0A0A0",
                        padding: "10px",
                        fontWeight: 500,
                      }}
                    >
                      Amount Paid
                    </td>
                    <td
                      className={`${Classes.table} text-start`}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#A0A0A0",
                        padding: "10px",
                        fontWeight: 500,
                      }}
                    >
                      Date
                    </td>
                    <td
                      className={`${Classes.table} text-start`}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#A0A0A0",
                        padding: "10px",
                        fontWeight: 500,
                      }}
                    >
                      Number
                    </td>
                    <td
                      className={`${Classes.table} text-start`}
                      style={{
                        backgroundColor: "#3A3F51",
                        color: "#A0A0A0",
                        padding: "10px",
                        fontWeight: 500,
                      }}
                    >
                      Email
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {filteredData 
                    // Only include items with a transaction ID
                    .map((item, index) => (
                      <tr key={index} style={{ borderBottom: "1px solid #3E3E3E" }}>
                        <td
                          className="text-start"
                          style={{ padding: "10px", color: "#EEEEEE" }}
                        >
                          {item.transaction_id}
                        </td>
                        <td
                          className="text-start"
                          style={{ padding: "10px", color: "#EEEEEE" }}
                        >
                          {item.student_details.firstname}
                        </td>
                        <td
                          className="text-start"
                          style={{ padding: "10px", color: "#A0A0A0" }}
                        >
                          {item.fee_collected}
                        </td>
                        <td
                          className="text-start"
                          style={{ padding: "10px", color: "#A0A0A0" }}
                        >
                          {item.date_of_fee_collection}
                        </td>
                        <td
                          className="text-start"
                          style={{ padding: "10px", color: "#A0A0A0" }}
                        >
                          {item.student_details.phone}
                        </td>
                        <td

                          style={{ padding: "10px", color: "#A0A0A0" }}
                        >
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="flex-start">
                              {item.student_details.email}
                            </div>
                            <div className="d-flex gap-4">
                              <img
                                src={view}
                                alt="view"
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  fontWeight: '700px',
                                  cursor: "pointer",
                                }}
                                title="View Receipt"
                                onClick={() => handleImageClick(item.studentid)} // Call function on click
                              />
                              <img
                                src={download}
                                
                                alt="Download"
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  cursor: "pointer",
                                }}
                                title="Download"
                                onClick={handleDownloadExcel}
                              />
                            </div>
                          </div>

                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default StudentData;
